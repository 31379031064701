<template>  
  <section class="hero is-fullheight">
    <div class="hero-head">
      <Navbar />
    </div>
    <div class="hero-body">
      <div class="container">

        <div style="max-width: 640px;">
          <h1 class="subtitle is-size-3">
            Join our closed beta
          </h1>
          <p class="mb-6">Swagible is currently available as a closed beta only. If you'd like to join, fill out the form below.</p>
          

          <FormField
            label="First Name"
            v-model="firstName"
          />

          <FormField
            label="Last Name"
            v-model="lastName"
          />

          <FormField
            label="Email"
            type="email"
            v-model="email"
          />

          <FormField
            label="Company"
            v-model="company"
          />

          <FormField
            label="Position / Role"
            v-model="role"
          />

          <button
            :class="`button is-black ${isPending && 'is-loading'}`"
            :disabled="!firstName || !lastName || !email || !company || !role"
            @click="handleSubmit()"
          >
            Submit
          </button>

          <p class="mt-5">Please allow 5-7 business days for a response.</p>
        </div>
      
      </div>
    </div>
    <div class="hero-foot">
      <Footer />
    </div>
  </section>
</template>

<script>
import { inject, ref } from 'vue'
import Navbar from '@/components/Navbar1.vue'
import FormField from '@/components/Forms/FormField.vue'
import Footer from '@/components/Footer.vue'
import useCollection from '@/composables/useCollection'
import { timestamp } from '@/firebase/config'

export default {
  components: { Navbar, FormField, Footer },
  setup() {
    const isPending = inject('isPending')

    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const company = ref('')
    const role = ref('')

    const handleSubmit = async () => {
      const { addDoc: addEmail } = useCollection('waitlist')
      await addEmail({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        company: company.value,
        role: role.value,
        created: timestamp()
      })

      // isAdded.value = true
    }

    return {
      firstName,
      lastName,
      email,
      company,
      role,

      isPending,
      handleSubmit,
    }
  }
}
</script>